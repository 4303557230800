import React from "react"
import SectionTitleDescriptionCta from "../section/section-title-description-cta"
import {
  ContactOptionsContentContainer,
  ContentItemContainer,
  CompanyInformationContainer,
  CenterContainer,
  ContentItemHeaderContainer,
} from "./components"
import Iframe from "react-iframe"
import Hidden from "@material-ui/core/Hidden"

import MessageIcon from "@material-ui/icons/Message"
import LiveHelp from "@material-ui/icons/LiveHelp"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { Link } from "gatsby"
import PopupDialog from "../popup/popup-dialog"

const address = {
  address: [
    "Tigase, Inc.",
    "100 Pine Street, #1250",
    "San Francisco CA 94111",
    "USA",
  ],
  mobilephone: {
    tel: "+1 (844) 273-0551",
    href: "tel:+1 (844) 273-0551",
    desc: "Toll free for USA and Canada",
  },
  mobilephoneInternational: {
    tel: "+1 (415) 900-4774",
    href: "+1 (415) 900-4774",
    desc: "International",
  },
  mobilephone247: {
    tel: "+1 (844) 273-0554",
    href: "tel:+1 (844) 273-0554",
    desc: "Toll free for USA and Canada",
  },
  mobilephone247International: {
    tel: "+1 (415) 900-4778",
    href: "tel:+1 (415) 900-4778",
    desc: "International",
  },
  email: "<office AT tigase.net>",
}

const ContactOptions = () => {
  return (
    <ContactOptionsContentContainer>
      <SectionTitleDescriptionCta
        title="Send us a message"
        description="If you have any questions or need more information, do not hesitate to send us a message."
      />
      <Grid container justify="center">
        <ContactButtonContainer>
          <ContactSales />
        </ContactButtonContainer>
        <ContactButtonContainer>
          <ContactTechnicalSupport />
        </ContactButtonContainer>
      </Grid>
    </ContactOptionsContentContainer>
  )
}

const ContactInformation = () => {
  const [open, setOpen] = React.useState(false)

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <div style={{ marginLeft: 15 }}>
      <h5>Address:</h5>
      <ContentItemContainer>
        {address.address.map(item => {
          return <div key={item}>{item}</div>
        })}
      </ContentItemContainer>
      <h5>Contact us:</h5>
      {/*<ContentItemHeaderContainer>Email:</ContentItemHeaderContainer>*/}
      {/*<ContentItemContainer>{address.email}</ContentItemContainer>*/}
      <ContentItemHeaderContainer>Sales:</ContentItemHeaderContainer>
      <ContentItemContainer>
        <p>
          <a href={address.mobilephone.href}>{address.mobilephone.tel} </a>
          {address.mobilephone.desc}
        </p>
        <p>
          <a href={address.mobilephoneInternational.href}>
            {address.mobilephoneInternational.tel}{" "}
          </a>
          {address.mobilephoneInternational.desc}
        </p>
      </ContentItemContainer>
      <ContentItemHeaderContainer>
        Support for subscribed Customers:
      </ContentItemHeaderContainer>
      <ContentItemContainer>
        <p>
          <a href={address.mobilephone247.href}>
            {address.mobilephone247.tel}{" "}
          </a>
          {address.mobilephone247.desc}
        </p>
        <p>
          <a href={address.mobilephone247International.href}>
            {address.mobilephone247International.tel}{" "}
          </a>
          {address.mobilephone247International.desc}
        </p>
      </ContentItemContainer>
      <ContentItemContainer>
        <Button onClick={handleClickOpen} variant="contained" color="primary">
          Contact US
        </Button>
      </ContentItemContainer>
      <PopupDialog handleClose={handleClose} open={open}>
        <ContactOptions />
      </PopupDialog>
    </div>
  )
}

export const ContactButtonContainer = props => {
  return (
    <Grid
      item
      xs={6}
      style={{
        display: "block",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      {props.children}
    </Grid>
  )
}

export const ContactSales = () => {
  return (
    <Link to="/contact-sales">
      <Button fullWidth style={{ display: "block" }}>
        <MessageIcon style={{ fontSize: 60, margin: "auto" }} />
        <p>Contact Sales</p>
      </Button>
    </Link>
  )
}

export const ContactTechnicalSupport = () => {
  return (
    <Link to="/technical-support">
      <Button fullWidth style={{ display: "block" }}>
        <LiveHelp style={{ fontSize: 60, margin: "auto" }} />
        <p>Contact Support</p>
      </Button>
    </Link>
  )
}

export const IconButtonContact = props => {
  return (
    <>
      {props.isLinkExternal ? (
        <a href={props.link}>
          <Button fullWidth style={{ display: "block" }}>
            {props.icon}
            <p>{props.text}</p>
          </Button>
        </a>
      ) : (
        <Link to={props.link}>
          <Button fullWidth style={{ display: "block" }}>
            {props.icon}
            <p>{props.text}</p>
          </Button>
        </Link>
      )}
    </>
  )
}

const Contact = () => {
  return (
    <>
      <CenterContainer>
        <CompanyInformationContainer>
          <div style={{ display: "flex" }}>
            <Hidden only="xs">
              <Iframe
                url="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=Tigase%2C%20Inc.%20100%20Pine%20Street%2C%20Suite%201250%20San%20Francisco%20CA%2094111%20USA+(tigase)&amp;ie=UTF8&amp;t=&amp;z=12&amp;iwloc=B&amp;output=embed"
                height="630px"
                width="630px"
                frameBorder="0"
                style={{ borderRadius: 25, maxWidth: 500 }}
              />
            </Hidden>
            <ContactInformation />
          </div>
        </CompanyInformationContainer>
      </CenterContainer>
    </>
  )
}

export default Contact
